var modalOpen = document.querySelector('#btn-modal-open');
var modalClose = document.querySelector('#btn-modal-close');
var modal = document.querySelector('.modal');

if (modalOpen) {
    modalOpen.addEventListener('click', function(e) {
        e.preventDefault();
        modal.classList.toggle('is-active');
    });
}

if (modalClose) {
    modalClose.addEventListener('click', function(e) {
        e.preventDefault();
        modal.classList.toggle('is-active');
    });
}
