<template>
    <div class="landing-section">
        <div class="region-field field">
            <div class="columns">
                <div class="column is-10">
                    <div class="building-swiper-wrapper">
                        <div class="swiper building-swiper-container swiper-container">
                            <ul class="typologies property-type swiper-wrapper">
                                <li
                                    class="typologies-items swiper-slide"
                                    v-for="fbt in buildingTypes"
                                    :key="fbt.slug"
                                >
                                    <a
                                        @click="selectedBuildingType=fbt.slug"
                                        class="typologies-item"
                                        :class="[`typologies-item-${fbt.singular}`, selectedBuildingType==fbt.slug ? 'selected-building-type': '']"
                                    >
                                        <span class="typologies-label">{{
                                                fbt.singular
                                            }}</span>
                                        <img v-if="fbt.backimg" :src="fbt.backimg" />
                                        <img
                                            v-if="fbt.frontimg"
                                            :src="fbt.frontimg"
                                            :alt="fbt.singular"
                                        />
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div class="swiper-button-next  building-swiper-button-next"></div>
                        <div class="swiper-button-prev  building-swiper-button-prev"></div>

                        <input name="buildingtype" type="hidden" :value="selectedBuildingType"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper } from 'swiper/bundle';

export default {
    props: {
        buildingTypes: {
            default: {}
        },
        translations: {
            required: true
        },
        initSelectedBuildingType: {
            default: null,
        },
    },
    data() {
        return {
            selectedBuildingType: this.initSelectedBuildingType,
        };
    },
    mounted() {
        this.initSwiper()
    },
    methods: {
        initSwiper() {
            const building = new Swiper('.building-swiper-container', {
                slidesPerView: 3,
                spaceBetween: 12,
                centerInsufficientSlides: false,
                allowTouchMove: false,
                watchOverflow: true,
                navigation: {
                    nextEl: '.building-swiper-button-next',
                    prevEl: '.building-swiper-button-prev',
                    disabledClass: 'disabled_swiper_button'
                },
                breakpoints: {
                    525: {
                        slidesPerView: 2,
                        allowTouchMove: true,
                    },
                    769: {
                        slidesPerView: 3,
                        allowTouchMove: true,
                    },
                    1024: {
                        slidesPerView: 4,
                    },
                }
            });
        }
    },
};
</script>
